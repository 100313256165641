<template>
  <div class="team">
    <v-card class="primary-shade--light pa-3">
      <v-row align="center" justify="center">
        <h3 class="mr-3 text--bold mt-5 mb-10"> العمليات</h3>
      </v-row>
      <div class="table_action">
        <div class="table_action_additional">
          <h2>واردات الطلاب</h2>
          <v-divider></v-divider>
          <ul v-for="(val, index) in dataStudent" :key="index">
            <li>
              <v-chip color="primary" outlined>
                {{ val.name }}
                <v-icon right class="mr-2 ml-1" @click="editItem(val)">
                  fa-edit
                </v-icon>
                <v-icon right class="mr-2" color="#FF8A80" @click="deleteItem(val)" v-if="val.salaryCount === 0">
                  fa-times-circle
                </v-icon>
              </v-chip>
            </li>
          </ul>
          <v-divider></v-divider>
          <v-btn rounded color="primary" class="mt-2" @click="addDialog.open = true, addData.type = 'واردات طلاب'">اضافة
            <v-icon right>
              fa-plus </v-icon>
          </v-btn>
        </div>
        <div class="table_action_discount">
          <h2>واردات اخرى</h2>
          <v-divider></v-divider>
          <ul v-for="(val, index) in dataOther" :key="index">
            <li>
              <v-chip color="primary" outlined>
                {{ val.name }}
                <v-icon right class="mr-2 ml-1" @click="editItem(val)">
                  fa-edit
                </v-icon>
                <v-icon right class="mr-2" color="#FF8A80" @click="deleteItem(val)" v-if="val.salaryCount === 0">
                  fa-times-circle
                </v-icon>
              </v-chip>
            </li>
          </ul>
          <v-divider></v-divider>
          <v-btn rounded color="primary" class="mt-2" @click="addDialog.open = true, addData.type = 'واردات اخرى'">اضافة
            <v-icon right>
              fa-plus </v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>

    <!-- add dialog -->
    <v-dialog v-model="addDialog.open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">اضافة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addDialog.isFormValidAdd">
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="addData.name" label="الاسم" :rules="rules.name" outlined></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addDialog.open = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="addDialog.loading" :disabled="!addDialog.isFormValidAdd"
            @click="addActionsData"> اضافة </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit Class dialog -->
    <v-dialog v-model="dialogEdit.open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">تعديل</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="dialogEdit.isFormValid">
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="editedItem.name" label="الاسم" :rules="rules.name" outlined></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEdit.open = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="dialogEdit.loading" :disabled="!dialogEdit.isFormValid"
            @click="editItemConform"> تعديل </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit Class dialog -->

    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline justify-center"> هل انت متأكد من حذف هذا العنصر ؟ </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDelete = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="deleteItemLoading" @click="deleteItemConfirm"> حذف </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import api from '@/api/api';

export default {
  data() {
    return {

      editedItem: {},

      dialogEdit: {
        open: false,
        isFormValid: false,
        loading: false,
      },

      deleteItemLoading: false,

      dialogDelete: false,

      addDialog: {
        open: false,
        isFormValidAdd: false,
        saveLoading: false,
        loading: false,
      },

      addData: {
        name: null,
        type: null
      },

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      isFormValidAdd: false,

      type: "استقطاع",

      type_select: ["استقطاع", "زيادة"],

      // leader_select: [],

      leader_select_filter: [],

      btnLoading: false,

      dataStudent: [],
      dataOther: [],

      rules: {
        name: [value => !!value || 'الاسم مطلوب'],
      },
    }
  },
  created() {
    this.getData()
  },

  methods: {
    goToPageAdding() {
      this.$router.push(`/actions/زيادة`)
    },

    goToPageDiscount() {
      this.$router.push(`/actions/استقطاع`)
    },

    async getData() {
      const responseStudent = await api.getService("واردات طلاب")
      const responseOther = await api.getService("واردات اخرى")
      if (responseStudent.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (responseStudent.status === 500) {
        this.table.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.dataStudent = responseStudent.data.results
        this.dataOther = responseOther.data.results
      }
    },

    editItem(item) {
      this.editedItem = { ...item }
      this.dialogEdit.open = true
    },

    async editItemConform() {
      this.dialogEdit.loading = true

      const response = await api.editService(this.editedItem.name, this.editedItem._id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false

        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false
        this.getData()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    async addActionsData() {
      this.addDialog.loading = true

      const response = await api.addService(this.addData.name, this.addData.type)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.getData()
        // this.showDialogfunction(response.data.results, 'primary')
      }
    },

    deleteItem(item) {
      this.deletedItem = { ...item }
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.deleteItemLoading = true

      const response = await api.removeService(this.deletedItem._id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.deleteItemLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.deleteItemLoading = false
        this.dialogDelete = false
        this.getData()
        // this.showDialogfunction(response.data.results, 'primary')
      }
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },
  },
}
</script>

<style>
.table_action {
  display: flex;
  justify-content: center;
  gap: 5rem;
}

@media only screen and (max-width: 600px) {
  .table_action {
    display: block;
    text-align: center;
  }

  .table_action_additional {
    margin-bottom: 15px;
    font-size: 15px;
  }

  .table_action_discount {
    font-size: 15px;
  }
}

.table_action li {
  padding: 5px;
  list-style: none;
}

.grid-container {
  display: grid;
  grid-template-columns: 200px 250px;
}
</style>
